import { createRouter, createWebHistory } from "vue-router";

const routes = [
  // {
  //   path: "/",
  //   redirect: "/", // 重定向:重新指向其它path,会改变网址
  // },
  {
    path: "/",
    name: "layout",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Layout/index.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/Layout/Home/index.vue"
          ),
      },

      {
        path: "/personalCenter",
        name: "personalCenter",
        component: () =>
          import(
            /* webpackChunkName: "personalCenter" */ "../views/Layout/PersonalCenter/index.vue"
          ),
      },
    ],
  },
  {
    path: "/await",
    name: "await",
    component: () =>
      import(/* webpackChunkName: "await" */ "../views/Await/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Layout/Login/index.vue"),
  },
  {
    path: "/sign",
    name: "sign",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Layout/Sign/index.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/404/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
