<template>
  <router-view />
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  // font-family: "黑体" 'Arial';
  font-family: "Noto Sans TC", Arial, Helvetica, sans-serif;
}
</style>
